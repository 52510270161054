import firebase from 'firebase/app';
import 'firebase/storage';

export const firebaseApp = firebase.initializeApp({
    apiKey: 'AIzaSyCqhjHwNAPN4RS-mDiHAN_hGvyQupXJi1E',
    authDomain: 'natan-9eeab.firebaseapp.com',
    projectId: 'natan-9eeab',
    storageBucket: 'natan-9eeab.appspot.com',
    messagingSenderId: '838299797637',
    appId: '1:838299797637:web:86d01afed6bd900ee841ec',
});

const storage = firebaseApp.storage();

export { storage };