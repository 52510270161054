<template>
	<v-app>
		<v-main>
			<v-container>
				<div class="text-center mt-4">
					<div class="display-1 font-weight-bold mb-5">Alles Gute zum</div>
					<div class="display-4 font-weight-bold mb-5">19🎉</div>
					<div class="display-2 font-weight-bold">Geburtstag</div>
					<div class="display-1 font-weight-bold">mein Freund</div>
				</div>
				<div class="pa-6 text-center">Scroll down</div>
			
 
			

				<v-sheet class="mx-auto text-center" max-width="336" id="item">
					<div class="display-3 font-weight-bold">Ein paar</div>
					<div class="display-3 font-weight-bold">Ausschnitte</div>
					<div class="display-3 font-weight-bold">aus diesem</div>
					<div class="display-3 font-weight-bold">Jahr</div>
			</v-container>

			<v-img id="item" v-for="(img, i) in imgs" :key="i"  :src="img">
				<template v-slot:placeholder>
					<v-row class="fill-height ma-0" align="center" justify="center">
						<v-progress-circular
							indeterminate
							color="grey lighten-5"
						></v-progress-circular>
					</v-row>
				</template>
			</v-img>

      <div class="mt-7 tect-center">
						<div class="font-weight-bold display-2">Falls du</div>
						<div class="font-weight-bold display-2">dich noch</div>
						<div class="font-weight-bold display-2">fragst, was dein</div>
						<div class="font-weight-bold display-2">Geschnk ist:</div>
						<div class="font-weight-bold display-2">es sind</div>
						<v-card
							flat
							style="border: green solid 1px"
							class="rounded-lg pa-3 font-weight-bold display-2 mt-5"
						>
							<a href="https://pikulik.de">pikulik.de</a>
						</v-card>
						<v-card
							flat
							style="border: green solid 1px"
							class="rounded-lg pa-3 font-weight-bold display-2 mt-5"
						>
							<a href="https://pikulik.berlin">pikulik.berlin</a>
						</v-card>
					</div>
		</v-main>
	</v-app>
</template>

<script>
import { storage } from "@/firebase.js";
export default {
	name: "App",
	data: () => ({
		imgs: [],
	}),
	mounted() {
		for (let i = 0; i < 37; i++) {
			storage
				.ref()
				.child(`${i}.png`)
				.getDownloadURL()
				.then((url) => {
					this.imgs.push(url);
					console.log(this.imgs);
				})
				.catch((error) => {
					// A full list of error codes is available at
					// https://firebase.google.com/docs/storage/web/handle-errors
					switch (error.code) {
						case "storage/object-not-found":
							// File doesn't exist
							break;
						case "storage/unauthorized":
							// User doesn't have permission to access the object
							break;
						case "storage/canceled":
							// User canceled the upload
							break;

						// ...

						case "storage/unknown":
							// Unknown error occurred, inspect the server response
							break;
					}
				});
		}
	},
};
</script>
